import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8b9e0f1e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "detail"
};
var _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Head = _resolveComponent("Head");

  var _component_el_col = _resolveComponent("el-col");

  var _component_el_row = _resolveComponent("el-row");

  var _component_downFileList = _resolveComponent("downFileList");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Head, {
    title: "我的通知",
    back: true
  }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "title"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_col, {
        span: 24
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", null, _toDisplayString(_ctx.title), 1)];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createElementVNode("div", {
    class: "content",
    innerHTML: _ctx.richText
  }, null, 8, _hoisted_2), _createVNode(_component_downFileList, {
    ref: "downFileList"
  }, null, 512)])], 64);
}